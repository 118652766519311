import { Injectable } from '@angular/core';
import { LayoutConfigService } from './layout-config.service';
import { Observable, Subject } from 'rxjs';
import { UserInfoService } from '@wlp/ui-bs-oauth-bo';
import { FeatureManagerService } from '@wlp/ui-bs-feature-manager';
import { of } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';

@Injectable()
export class MenuConfigService {
  // Public properties
  onConfigUpdated$: Subject<any>;
  // Private properties
  private menuConfig: any;

  /**
   * Service Constructor
   */
  constructor(
    private layoutConfigService: LayoutConfigService, 
    private userInfoService: UserInfoService, 
    private featureManager: FeatureManagerService) {
    // register on config changed event and set default config
    this.onConfigUpdated$ = new Subject();
  }

  /**
   * Returns the menuConfig
   */
  async getMenus() {
    return this.menuConfig;
  }

  /**
   * Load config
   *
   * @param config: any
   */
  loadConfigs(config: any) {
    this.menuConfig = config;

    this.processRoles();

    this.onConfigUpdated$.next(this.menuConfig);
  }

  private rolesUserAccess() {
    const rolesUser = this.userInfoService.getRoles();
    return rolesUser.filter((obj) => obj.includes('bo-'));
  }
  /**
   * Config para exibir o menu Prospera
   */
  private async processRoles() {
    // let menus ={
    //   header: {
    //     self: {},
    //     items: [ ],
    //   },
  
    //   aside: {
    //     self: {},
    //     items: await this.loadMenuApi().toPromise(),
    //   },
    // };
    // const menuOrigin = await Object.assign([], this.menuConfig.aside.items);
    // const rolesUser = this.rolesUserAccess();
    // console.log('processRoles', rolesUser);
    // this.menuConfig.aside.items = [];

    this.menuConfig.aside.items = await this.loadMenuApi().toPromise()

    // menuOrigin.forEach((menu) => {
    //   // process submenu
    //   if (menu.submenu && menu.submenu.length > 0) {
    //     const children = menu.submenu.filter((submenu) => {
    //       // check permission
    //       if (submenu.roles) {
    //         let addSubmenu = false;

    //         if (Array.isArray(submenu.roles)) {
    //           submenu.roles.forEach((role) => {
    //             if (rolesUser.includes(role)) {
    //               addSubmenu = true;
    //             }
    //           });
    //         } else {
    //           if (rolesUser.includes(submenu.roles)) {
    //             addSubmenu = true;
    //           }
    //         }

    //         return addSubmenu;
    //       } else {
    //         return true;
    //       }
    //     });
    //     menu.submenu = children;
    //   }

    //   let addMenu = false;

    //   if (menu.roles) {
    //     if (Array.isArray(menu.roles)) {
    //       menu.roles.forEach((role) => {
    //         if (rolesUser.includes(role)) {
    //           addMenu = true;
    //         }
    //       });
    //     } else {
    //       if (rolesUser.includes(menu.roles)) {
    //         addMenu = true;
    //       }
    //     }
    //   }

    //   if (addMenu) {
    //     this.menuConfig.aside.items = menus
    //     console.log('Menu config', this.menuConfig)
    //   }
    // });
    

    // console.log('menuOrigin', menuOrigin)

    // if (rolesUser.includes('bo-access')) {
    //   if (this.menuConfig.aside.items.length > 0) {
    //     this.menuConfig.aside.items = this.menuConfig.aside.items.concat(menuOrigin.filter(this.isAccess));
    //   } else {
    //     this.menuConfig.aside.items = menuOrigin.filter(this.isAccess);
    //   }
    // }

    // if (rolesUser.includes('bo-manager-panic')) {
    //   if (this.menuConfig.aside.items.length > 0) {
    //     this.menuConfig.aside.items = this.menuConfig.aside.items.concat(menuOrigin.filter(this.isManagerPanic));
    //   } else {
    //     this.menuConfig.aside.items = menuOrigin.filter(this.isManagerPanic);
    //   }
    // }

    // if (rolesUser.includes('bo-psx-admin')) {
    //   if (this.menuConfig.aside.items.length > 0) {
    //     this.menuConfig.aside.items = this.menuConfig.aside.items.concat(menuOrigin.filter(this.isPsxAccess));
    //   } else {
    //     this.menuConfig.aside.items = menuOrigin.filter(this.isPsxAccess);
    //   }
    // }

    // if (rolesUser.includes('bo-wl-admin')) {
    //   if (this.menuConfig.aside.items.length > 0) {
    //     this.menuConfig.aside.items = this.menuConfig.aside.items.concat(menuOrigin.filter(this.isAccessWL));
    //   } else {
    //     this.menuConfig.aside.items = menuOrigin.filter(this.isAccessWL);
    //   }
    // }

    // if (rolesUser.includes('bo-admin')) {
    //   if (this.menuConfig.aside.items.length > 0) {
    //     this.menuConfig.aside.items = this.menuConfig.aside.items.concat(menuOrigin.filter(this.isAdminAccess));
    //   } else {
    //     this.menuConfig.aside.items = menuOrigin.filter(this.isAdminAccess);
    //   }
    // }

    // if (rolesUser.includes('bo-ppr-admin')) {
    //   if (this.menuConfig.aside.items.length > 0) {
    //     this.menuConfig.aside.items = this.menuConfig.aside.items.concat(menuOrigin.filter(this.isProsperaAccess));
    //   } else {
    //     this.menuConfig.aside.items = menuOrigin.filter(this.isProsperaAccess);
    //   }
    // }

    // if (rolesUser.includes('bo-manual-rescue')) {
    //   if (this.menuConfig.aside.items.length > 0) {
    //     this.menuConfig.aside.items = this.menuConfig.aside.items.concat(menuOrigin.filter(this.isManualRescue));
    //   } else {
    //     this.menuConfig.aside.items = menuOrigin.filter(this.isManualRescue);
    //   }
    // }

    // if (rolesUser.includes('bo-noname-lot')) {
    //   if (this.menuConfig.aside.items.length > 0) {
    //     this.menuConfig.aside.items = this.menuConfig.aside.items.concat(menuOrigin.filter(this.isNonameLot));
    //   } else {
    //     this.menuConfig.aside.items = menuOrigin.filter(this.isNonameLot);
    //   }
    // }
  }

  public loadMenuApi(): Observable<any[]> {
    return this.featureManager.getAllMenu()
      .pipe(
        map((res: any) => {
          const categories = res.data;
          const menusMap = {};
          const menus = categories.filter(category => {
            if (category.category === 'MENU') {
              // Inicializar o array de submenus para o menu
              category.submenu = [];

              // Mapear o menu pelo seu UUID
              menusMap[category.uuid] = category;
              return true;
            }
            return false;
          });

          // Adicionar os submenus aos menus correspondentes
          categories.forEach(category => {
            if (category.category === 'SUBMENU' && category.uuidParent in menusMap) {
              menusMap[category.uuidParent].submenu.push(category);
            } else if (category.category !== 'SUBMENU' && category.category !== 'MENU') {
              menus.push(category);
            }
          });

          menus.sort((a, b) => a.sequenceWeb - b.sequenceWeb);
          // Retorna os menus como um array
          return menus;
        }),
        catchError(err => {
          console.error(err);
          return of([]); // Retorna um array vazio em caso de erro
        })
      );
  }

  // isAccess(element, index, array) {
  //   if (element?.roles) {
  //     // console.log('isAccess');
  //     const roles = element.roles.filter((obj) => obj.includes('bo-access'));
  //     if (roles && roles.length > 0) {
  //       return true;
  //     } else {
  //       return false;
  //     }
  //   } else {
  //     return false;
  //   }
  // }

  // isManagerPanic(element, index, array) {
  //   if (element?.roles) {
  //     const roles = element.roles.filter((obj) => obj.includes('bo-manager-panic'));

  //     if (roles && roles.length > 0) {
  //       return true;
  //     } else {
  //       return false;
  //     }
  //   } else {
  //     return false;
  //   }
  // }

  // isAccessWL(element, index, array) {
  //   if (element?.roles) {
  //     // console.log('isAccessWL');
  //     const roles = element.roles.filter((obj) => obj.includes('bo-wl-admin'));
  //     if (roles && roles.length > 0) {
  //       return true;
  //     } else {
  //       return false;
  //     }
  //   } else {
  //     return false;
  //   }
  // }

  // isAdminAccess(element, index, array) {
  //   // console.log('isAdminAccess');
  //   if (element?.roles) {
  //     const roles = element.roles.filter((obj) => obj.includes('bo-admin'));
  //     if (roles && roles.length > 0) {
  //       return true;
  //     } else {
  //       return false;
  //     }
  //   } else {
  //     return false;
  //   }
  // }

  // isProsperaAccess(element, index, array) {
  //   // console.log('isProsperaAccess');
  //   if (element?.roles) {
  //     // console.log(element.title, element.roles.filter((obj) => obj.includes('bo-access')));
  //     const roles = element.roles.filter((obj) => obj.includes('bo-ppr-admin'));

  //     if (roles && roles.length > 0) {
  //       return true;
  //     } else {
  //       return false;
  //     }
  //   } else {
  //     return false;
  //   }
  // }

  // isPsxAccess(element, index, array) {
  //   // console.log('isProsperaAccess');
  //   if (element?.roles) {
  //     // console.log(element.title, element.roles.filter((obj) => obj.includes('bo-access')));
  //     const roles = element.roles.filter((obj) => obj.includes('bo-psx-admin'));

  //     if (roles && roles.length > 0) {
  //       return true;
  //     } else {
  //       return false;
  //     }
  //   } else {
  //     return false;
  //   }
  // }

  // isManualRescue(element, index, array) {
  //   if (element?.roles) {
  //     const roles = element.roles.filter((obj) => obj.includes('bo-manual-rescue'));

  //     if (roles && roles.length > 0) {
  //       return true;
  //     } else {
  //       return false;
  //     }
  //   } else {
  //     return false;
  //   }
  // }
}
