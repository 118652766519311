// Angular
import { Injectable } from '@angular/core';
// RxJS
import { BehaviorSubject } from 'rxjs';
// Object path
import * as objectPath from 'object-path';
// Services
import { MenuConfigService } from './menu-config.service';
import {UserInfoProfile, UserInfoService} from "@wlp/ui-bs-oauth-bo";

@Injectable()
export class MenuAsideService {
  // Public properties
  menuList$: BehaviorSubject<any[]> = new BehaviorSubject<any[]>([]);

  /**
   * Service constructor
   *
   * @param menuConfigService: MenuConfigService
   * @param userInfoService: UserInfoService
   */
  constructor(private menuConfigService: MenuConfigService, private userInfoService: UserInfoService) {
    this.loadMenu();
  }

  /**
   * TO-DO(Prospera): Organizar a logica de permissões para remover o item prospera do menu quando necessario.
   * Load menu list
   */
  loadMenu() {
    this.loadUser();
  }

  private loadUser() {
    try {
      const userDetails = this.userInfoService.getInfoProfile();
      userDetails.then(user => {
        this.loadMenuList(user);
      });
    } catch (e) {
      console.log('Failed to load user details', e);
    }
  }

  private async loadMenuList(user: UserInfoProfile) {
    // get menu list
    let menu = {
      header: {
        self: {},
        items: [],
      },
  
      aside: {
        self: {},
        items: await this.menuConfigService.loadMenuApi().toPromise(),
      },
    }
    let menuItems: any[] = objectPath.get(menu, 'aside.items');
    this.menuList$.next(menuItems);
  }

}
