import { CommonModule } from '@angular/common';
import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';
// Angular
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';

// Pages
import { CoreModule } from '../../core/core.module';
// Partials
import { PartialsModule } from '../partials/partials.module';
import { PagesRoutingModule } from './pages-routing.module';
import { UiBsUserInterestModule } from '@wlp/ui-bs-user-interest';
import {InterceptService} from "../../core/intercept/intercept.service";
import { UiBsTokenModule} from "@wlp/ui-bs-token";
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';


@NgModule({
  exports: [],
  imports: [
    CommonModule,
    HttpClientModule,
    FormsModule,
    CoreModule,
    PartialsModule,
    PagesRoutingModule,
    UiBsUserInterestModule,
    UiBsTokenModule,
    NgxMatSelectSearchModule
  ],
  providers: [
    InterceptService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: InterceptService,
      multi: true,
    },
  ],
})
export class PagesModule { }
