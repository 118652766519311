import { RouterModule, Routes } from '@angular/router';

// Components
import { BaseComponent } from '../theme/base/base.component';
// Auth
import { CanAuthenticationGuard } from '../../core/auth/can-authentication-guard';
// Angular
import { NgModule } from '@angular/core';

const routes: Routes = [
  {
    path: '',
    component: BaseComponent,
    canActivate: [CanAuthenticationGuard],
    data: { roles: ['bo-access'] },
    children: [
      {
        path: 'dashboard',
        loadChildren: () => import('../pages/dashboard/dashboard.module').then((m) => m.DashboardModule),
      },
      {
        path: 'implantation',
        loadChildren: () =>
          import('../pages/implantation-management/implantation-management.module').then(
            (m) => m.ImplantationManagementModule
          ),
      },
      {
        path: 'financial',
        loadChildren: () =>
          import('../pages/financial-management/financial-management.module').then((m) => m.FinancialManagementModule),
      },
      {
        path: 'transaction-analysis',
        loadChildren: () =>
          import('../pages/transaction-analysis/transaction-analysis.module').then((m) => m.TransactionAnalysisModule),
      },
      /*{
        path: 'users',
        loadChildren: () =>
          import('../pages/user-management/user-management.module').then((m) => m.UserManagementModule),
      },*/
      {
        path: 'account-management',
        loadChildren: () =>
          import('../pages/account-management/account-management.module').then((m) => m.AccountManagementModule),
      },
      {
        path: 'risk-management',
        loadChildren: () =>
          import('../pages/risk-management/risk-management.module').then((m) => m.RiskManagementModule),
      },
      {
        path: 'prosperaenergy-management',
        loadChildren: () =>
          import('../pages/prosperaenergy-management/prosperaenergy-management.module').then(
            (m) => m.ProsperaenergyManagementModule
          ),
      },
      {
        path: 'cockpit',
        loadChildren: () => import('../pages/cockpit/cockpit.module').then((m) => m.CockpitModule),
      },
      {
        path: 'tax-management',
        loadChildren: () => import('../pages/tax-management/tax-management.module').then((m) => m.TaxManagementModule),
      },
      {
        path: 'card-management',
        loadChildren: () =>
          import('../pages/card-management/card-management.module').then((m) => m.CardManagementModule),
      },
      {
        path: 'device-manager',
        loadChildren: () =>
          import('../pages/device-manager/device-manager.module').then((m) => m.DeviceManagerModule),
      },
      {
        path: 'accounts-closure',
        loadChildren: () =>
          import('../pages/accounts-closure/accounts-closure.module').then((m) => m.AccountsClosureModule),
      },
      {
        path: 'blacklist',
        loadChildren: () =>
          import('../pages/blacklist/blacklist.component.module').then((m) => m.BlacklistModule),
      },
      {
        path: 'file-regulatory',
        loadChildren: () =>
          import('../pages/file-management/file-regulatory/file-regulatory.component.module').then((m) => m.FileRegulatoryModule),
      },
      {
        path: 'onboarding/restrict-location',
        loadChildren: () =>
          import('../pages/restrict-location/restrict-location.component.module').then((m) => m.RestrictLocationModule),
      },
      {
        path: 'infract-report',
        loadChildren: () =>
          import('../pages/infraction-report/infraction-report/infraction-report.component.module').then((m) => m.InfractionReportModule),
      },
      {
        path: 'infract-report-actions',
        loadChildren: () =>
          import('../pages/infraction-report/infraction-report-view-or-action/infraction-report-view-or-action.component.module').then((m) => m.InfractionReportActionstModule),
      },
      {
        path: 'infract-med',
        loadChildren: () =>
          import('../pages/infraction-report/infraction-med/infraction-med.component.module').then((m) => m.InfractionMedModule),
      },
      {
        path: 'infract-med-actions',
        loadChildren: () =>
          import('../pages/infraction-report/infraction-med-view-or-action copy/infraction-med-view-or-action.component.module').then((m) => m.InfractionMedActionstModule),
      },
      {
        path: 'transactions-list-all',
        loadChildren: () =>
          import('../pages/infraction-report/infraction-list-transactions/infraction-list-transactions.component.module').then((m) => m.InfractionListTransactionsModule),
      },      {
        path: 'transaction-view',
        loadChildren: () =>
          import('../pages/infraction-report/transaction-view/transaction-view.component.module').then((m) => m.TransactionViewtModule),
      },

      
      // { path: 'error/:type', component: ErrorPageComponent },

      { path: '', redirectTo: 'dashboard', pathMatch: 'full' },
      { path: '**', redirectTo: 'dashboard', pathMatch: 'full' },
    ],
  },
  { path: '**', redirectTo: 'error/403', pathMatch: 'full' },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class PagesRoutingModule {}
