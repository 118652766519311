import { Subscription } from 'rxjs';

// Angular
import { ChangeDetectionStrategy, Component, OnDestroy, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { NavigationEnd, Router } from '@angular/router';

// Layout
import { LayoutConfigService, SplashScreenService, TranslationService } from './core/_base/layout';
// language list
import { locale as ptLang } from './core/_config/i18n/en';

@Component({
  // tslint:disable-next-line:component-selector
  selector: 'body[kt-root]',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AppComponent implements OnInit, OnDestroy {
  // Public properties
  title = 'Metronic';
  wlName: string;
  loader: boolean;
  private unsubscribe: Subscription[] = []; // Read more: => https://brianflove.com/2016/12/11/anguar-2-unsubscribe-observables/

  /**
   * Component constructor
   *
   * @param translationService: TranslationService
   * @param router: Router
   * @param layoutConfigService: LayoutConfigService
   * @param splashScreenService: SplashScreenService
   */
  constructor(
    private translationService: TranslationService,
    private router: Router,
    private layoutConfigService: LayoutConfigService,
    private splashScreenService: SplashScreenService,
    private titleService: Title
  ) {
    // register translations
    this.translationService.loadTranslations(ptLang);
    this.wlName = this.layoutConfigService.getConfig('wlTheme.wlName');
  }

  /**
   * @ Lifecycle sequences => https://angular.io/guide/lifecycle-hooks
   */

  /**
   * On init
   */
  ngOnInit(): void {
    this.titleService.setTitle(this.wlName + ' | Backoffice');

    // enable/disable loader
    this.loader = this.layoutConfigService.getConfig('page-loader.type');

    const routerSubscription = this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        // hide splash screen
        this.splashScreenService.hide();

        // scroll to top on every route change
        window.scrollTo(0, 0);

        // to display back the body content
        setTimeout(() => {
          document.body.classList.add('page-loaded');
        }, 500);
      }
    });
    this.unsubscribe.push(routerSubscription);
    this.setPropertyStyles();
  }

  /**
   * On Destroy
   */
  ngOnDestroy() {
    this.unsubscribe.forEach((sb) => sb.unsubscribe());
  }

  setPropertyStyles() {
    // Aside Vars
    let asideBgColor = this.layoutConfigService.getConfig('wlTheme.aside.background.color');
    let asideTxtColor = this.layoutConfigService.getConfig('wlTheme.aside.text.color');
    let asideTxtHover = this.layoutConfigService.getConfig('wlTheme.aside.text.hover');
    let favicon = this.layoutConfigService.getConfig('wlTheme.favicon')

    // Aside Sets
    document.documentElement.style.setProperty('--ui-t-aside-background-color', asideBgColor);
    document.documentElement.style.setProperty('--ui-t-aside-text-color', asideTxtColor);
    document.documentElement.style.setProperty('--ui-t-aside-text-hover', asideTxtHover);
    document.getElementById('appIcon').setAttribute('href', favicon);
  }
}
