export class MenuConfig {
  public defaults: any = {
    header: {
      self: {},
      items: [],
    },

    aside: {
      self: {},
      items: [
        
      ],
    },
  };

  public get configs(): any {
    return this.defaults;
  }
}
