// Angular
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule, Routes } from '@angular/router';


const routes: Routes = [

  { path: 'error', loadChildren: () => import('./views/pages/error/error.module').then(m => m.ErrorModule) },

  { path: '', redirectTo: 'app', pathMatch: 'full' },

  { path: 'app', loadChildren: () => import('./views/theme/theme.module').then(m => m.ThemeModule) },

  { path: 'account-management', loadChildren: () => import('./views/pages/account-management/account-management.module').then(m => m.AccountManagementModule) },

  { path: '**', redirectTo: 'error/403', pathMatch: 'full' },
];

@NgModule({
  imports: [
    CommonModule,
    RouterModule.forRoot(routes),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {
}
